
import { useState, useEffect, useRef } from "react";
import '../stylesheets/about.css';

function gid(id){return document.getElementById(id);}

function About() {

  const [skills, setSkills] = useState([]);
  const cp = useRef(null);
  const mySkills = useRef(null);


  useEffect(() => {

    let cw= mySkills.current.offsetWidth;
    let ch= mySkills.current.offsetHeight;

    // setSkills([
    //   { px:0, py:0, dx:1, dy:1, name:"JavaScript", color: 'yellow'},
    //   { px:cw-80, py:ch-60, dx:-1, dy:1, name:"HTML", color: 'orange'},
    //   { px:300, py:ch-80, dx:1, dy:1, name:"CSS", color: 'blue'},
    //   { px:cw/2, py:50, dx:-1, dy:1, name:"BootStrap", color: '#66ccff'},
    //   { px:217, py:300, dx:-1, dy:-1, name:"Jquery", color: 'white'},
    //   { px:cw-200, py:134, dx:-1, dy:1, name:"Angular", color: 'red'},
    //   { px:cw-300, py:25, dx:1, dy:-1, name:"ReactJs", color: 'cyan'},
    //   { px:cw-150, py:120, dx:-1, dy:-1, name:"PHP", color: 'gray'},
    //   { px:cw-250, py:180, dx:1, dy:-1, name:"MongoDB", color: 'orangred'},
    //   { px:123, py:ch-100, dx:1, dy:1, name:"NodeJs", color: 'green'},
    //   { px:cw-100, py:ch-225, dx:-1, dy:-1, name:"SQL", color: '#99ffcc'},
    //   { px:cw-300, py:ch-300, dx:1, dy:1, name:"Laravel", color: '#b33610'}
    // ]);

    let url = "/skills.php";
    const rep = (n) => {
      if (n.includes('-')) {
        n =  n.replace("cw", cw).replace("ch", ch);
        const [left, right] = n.split('-').map(Number);
        return left - right;
      }
      else{return Number(n)}
    }

    //fetch from url
    fetch(url,{
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => setSkills(
        data.map((d) => ({...d, px:rep(d.px), py:rep(d.py)}))
      ));


  }, [])


  useEffect(() => {

    let step =1;

    requestAnimationFrame(skillsMove);

    function skillsMove(){
      if(skills.length>0){
        for(let i=0;i<skills.length;i++){
          let o= skills[i];
          hit(o, "container", mySkills.current.offsetWidth, 0, mySkills.current.offsetHeight, 0);
          let cpw= cp.current.offsetWidth;
          let cph= cp.current.offsetHeight;
          let cpx= (mySkills.current.offsetWidth/2) - (cpw/2);
          let cpy= (mySkills.current.offsetHeight/2) - (cph/2);
          hit(o, "not", cpx, cpx+cpw, cpy, cpy+cph);
          for(let y=0;y<skills.length;y++){
            let o2= skills[y];
            let e = gid(o2.name);
            if(i !== y){hit(o, "not", o2.px, o2.px+e.offsetWidth, o2.py, o2.py+e.offsetHeight);}
          }

        }
        

        const updatedSkills = skills.map(skill => ({
          ...skill,
          px: skill.px + step * skill.dx,
          py: skill.py + step * skill.dy
        }));
        setSkills(updatedSkills);
      }
      
    }

    function hit(o,n,x1,x2,y1,y2){
      let e = gid(o.name);
      if( (Math.abs( (o.px+e.offsetWidth)-x1 ) <= step) && (( o.py+e.offsetHeight>=y1 && y1>= o.py-(y2-y1) )|| (n==="container")) ){o.dx=-1}
      if( (Math.abs( (o.py+e.offsetHeight)-y1 ) <= step) && (( o.px+e.offsetWidth>=x1 && x1>= o.px-(x2-x1) )|| (n==="container")) ){o.dy=-1}
      if( (Math.abs( o.px - x2 ) <= step) && (( o.py+e.offsetHeight>=y1 && y1>= o.py-(y2-y1) )|| (n==="container")) ){o.dx=1}
      if( (Math.abs( o.py -y2 ) <= step) && (( o.px+e.offsetWidth>=x1 && x1>= o.px-(x2-x1) )|| (n==="container")) ){o.dy=1}
    }

  }, [skills]);


  return (

  <section className="sk-p reveal" id="about">
    <article className="about reveal">
        <h2><span></span> About Me <span></span></h2>
        <br/>
        <p>
        I'm a full stack web developer and software engineer who is passionate about coding and creative designs and building things.<br/><br/>
        Always Interested in working with ambitious people and working in creative and productive projects.
        </p>
    </article>
    
    <article id="skills" ref={mySkills}>
      <h2 id="centerPhrase" ref={cp} className="gradient-border"> <svg>  <ellipse className="po reveal" cx="50%" cy="50%" rx="48%" ry="48%"/></svg>My Skills</h2>
      
      {skills.map((e,key)=>{
        return <span key={key} id={e.name} className="se" style={{color:e.color,left:e.px.toString()+"px",top:e.py.toString()+"px"}}>
          {e.name}
          </span>
      })}

      {/* <span id="js" className="se" style={{color:"yellow"}}>JavaScript</span>
      <span id="htm" className="se" style={{color:"orange"}}>HTML</span>
      <span id="css" className="se " style={{color:"blue"}}>CSS</span>
      <span id="BootStrap" className="se " style={{color:"#66ccff"}}>BootStrap</span>
      <span id="Jquery" className="se " style={{color:"white"}}>jQuery</span>
      <span id="Angular" className="se " style={{color:"red"}}>Angular</span>
      <span id="ReactJs" className="se " style={{color:"cyan"}}>React</span>
      <span id="PHP" className="se " style={{color:"gray"}}>PHP</span>
      <span id="MongoDB" className="se " style={{color:"orangred"}}>MongoDB</span>
      <span id="NodeJs" className="se " style={{color:"green"}}>NodeJs</span>
      <span id="SQL" className="se " style={{color:"#99ffcc"}}>SQL</span>
      <span id="Laravel" className="se " style={{color:"#b33610"}}>Laravel</span> */}

    </article>
  </section>
  );

}

export default  About;
