
import { useState, useEffect } from "react";
import '../stylesheets/work.css';



function Video({keyProp, v}){
  return (<video key={keyProp} controls muted autoPlay preload="metadata">
          <source src={ '/' + v} type="video/webm"/>
          <source src={ '/' + v} type="video/mp4"/>
          Your browser does not support the video tag.
          </video>);
}

function Work() {

  const [wem, setwem] = useState({bg:'',video:'',link:'',code:'',description:''});

  const [we, setwe] = useState([]);

  const changeVid = (e) => {
    setwem(e);
  }

  useEffect(() => {

    let url = "/projects.php";

    //fetch from url
    fetch(url,{
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => setwe(data));


  }, []);


return(
<section className="reveal" id="work">

 <h1 className="w-t ">Some Things I’ve Built</h1>

 <div className="w-c reveal">
 {we.map((e,key) =>
   <div key={key} className="w-e " data-bs-toggle="modal"  data-bs-target="#work-show" onClick={()=>changeVid(e)} style={{backgroundImage:`url(${e.bg})`,animationDelay:`${(key+1)*0.5}s`}}>
     <div>View<br/>Project</div>
   </div>
 )}
 </div>

 <article className="modal" id="work-show">
   <div className="modal-dialog">
     <div className="modal-content">

       <div className="modal-header">
         <h4 className="modal-title"></h4>
         <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
       </div>

       <div className="modal-body">
       <div className="w-v">
        <Video keyProp={wem.video} v={wem.video}/>
       </div>

        <div className="w-d">
        <span>
        {wem.description}
        </span>
        <br/>
        <span>Link : <a href={wem.link} target="_blank" >{wem.link}</a></span>
        <span>Code : <a href={wem.code} target="_blank">{wem.code}</a></span>
        </div>

       </div>

       <div className="modal-footer">
         <button type="button" className="btn c-b" data-bs-dismiss="modal">Close</button>
       </div>

     </div>
   </div>
 </article>

</section>

);


}

export default  Work;
