
import { useState, useEffect } from "react";
import '../stylesheets/contact.css';
import gmailIcon from '../imgs/gmail-icon.png';
import whatsappIcon from '../imgs/whatsapp-icon.png';
import linkedinIcon from '../imgs/linkedin-icon.png';
import githubIcon from '../imgs/github-icon.png';
import facebookIcon from '../imgs/facebook-icon.png';
function Contact() {


return(
<section className="reveal" id="contact">

<h2>Contact Me On</h2>

<div className="contant-buttons-container">
<a href="mailto:mostafa.ahmed2509@gmail.com"><div style={{backgroundImage:`url(${gmailIcon})`}}></div></a>
<a href="https://wa.me/201066068795" target="_blank"><div style={{backgroundImage:`url(${whatsappIcon})`}}></div></a>
<a href="https://www.linkedin.com/in/mostafa-abu-elkhair/" target="_blank"><div style={{backgroundImage:`url(${linkedinIcon})`}}></div></a>
<a href="https://www.facebook.com/mostafa.abuelkhair.9" target="_blank"><div style={{backgroundImage:`url(${facebookIcon})`}}></div></a>

</div>

<form className="message-form" action="message.php" method="POST" target="#">
<span>Or you can just send me a message here :</span>
<div><label for="from">From:</label><input type="text" id="from" name="from"/></div>
<div><label for="subject">Subject:</label><input type="text" id="subject" name="subject"/></div>
<div><label for="body">Body:</label><textarea className="message-form-bodyInput" id="body" name="body"/></div>
<button type="submit">send</button>
</form>

</section>

);


}

export default  Contact;
