import logo from './imgs/logo4.png';
import './stylesheets/App.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useState, useEffect } from "react";
import {postFetch ,revealListen} from './modules/modules.js'
import './stylesheets/hi.css';
import About from './views/About';
import Work from './views/Work';
import Contact from './views/Contact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

function App() {
const [feData, setFeData] = useState('');

useEffect(() => {
revealListen();
  /*var func =(res)=>{
  setFeData(JSON.parse(res).x);
 }
  postFetch({},func);*/

}, []);

  const hideMenu = ()=>{
    const menueElement = document.getElementById("menu");
    menueElement.classList.remove("show");
  };

  return (
    <div className="App">
      <header className="App-header ">

      <div className="container-fluid ">

        <div className="row main">

          <nav className="col-xl-1 p-0 menu" id="menu">

          <a href="#hi"><div className=" h-25 logo" style={{backgroundImage:`url(${logo})`}}></div></a>
          <a href="#about"><span className="m-i h-25" onClick={hideMenu}>About</span></a>
          <a href="#work"><span className="m-i h-25" onClick={hideMenu} >My Work</span></a>
          <a href="#contact"><span className="m-i h-25" onClick={hideMenu}>Contact</span></a>

          </nav>


          <div className="col-xl-11 p-0 page1">

          <nav className="navbar navbar-expand-xl bg-dark navbar-dark" id="menu-b">
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu">
                <span className="navbar-toggler-icon"></span>
              </button>
          </nav>

          <section className="d-flex justify-content-center flex-column align-items-center">
          <p id="hi">
          <span className="m a1">Hi,<br/></span>
          <span className="m a2">I'm </span>
          <span className="m a3"><span style={{color:"orange"}}>Mostafa</span>,</span>
          <span className="m a4"><br/>a </span>
          <span className="m a5">Web</span>
          <span className="m a6"> Devoloper</span>
          </p>

          <a href="#contact" className="text-d-n">
          <button className="contact-button" >
           <span>Contact Me</span>
           <svg>
       		  <polyline className="po" points="0 0, 250 0, 250 80, 0 80, 0 0"></polyline>
       	  </svg>
           </button>
          </a>
           </section>

            <About />

            <Work />

            <Contact />

            <footer className='my-github'><a href='https://github.com/mostafa-abuelkhair'><FontAwesomeIcon className='github-icon' icon={faGithub}/></a></footer>

          </div>


        </div>
      </div>

      </header>
    </div>
  );
}



export default App;
