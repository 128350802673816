function postFetch(data,set){

  fetch("http://localhost:3000", {

    method: "POST",

    body: JSON.stringify(data)

})
.then(r => r.text())
.then(res => {set(res)});

}

function revealListen(){

function reveal() {
  var reveals = document.querySelectorAll(".reveal");
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
const page1 = document.getElementsByClassName("page1")[0];
page1.addEventListener("scroll", reveal);
reveal();

}


export {postFetch,revealListen} ;
